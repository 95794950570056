import type React from 'react'

import Container from '@mui/material/Container'

import type { StageBrandMediaReferences, StageProps } from './Stage.types'
import StageBrand from './StageBrand'
import StageFull from './StageFull'
import StageSplit from './StageSplit'

const Stage: React.FC<StageProps & Partial<StageBrandMediaReferences>> = ({
  stageType,
  dataCy = 'stage',
  ...props
}) => {
  const stageComponents = {
    splitStage: <StageSplit stageType={stageType} {...props} dataCy={dataCy} />,
    fullStage: <StageFull stageType={stageType} {...props} dataCy={dataCy} />,
    brandStage: (
      <StageBrand
        stageType={stageType}
        bgImageSmall={props.bgImageSmall}
        bgImageLarge={props.bgImageLarge}
        {...props}
        dataCy={dataCy}
      />
    ),
  }

  return (
    <Container maxWidth={false} disableGutters data-cy={`${dataCy}-wrapper`}>
      {stageComponents[stageType] || null}
    </Container>
  )
}

export default Stage
