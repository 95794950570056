import Image from 'next/image'
import Link from 'next/link'

import type { ComponentAnalyticsClickProps } from '@cms/analytics/types'
import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import useNormalizeReference from '@cms/hooks/useNormalizeReference'
import { FALLBACK_IMAGES, SMART_CROP_TOKENS, STAGE_BRAND_IMAGES } from '@cms/utils/constants'
import {
  getDevicePixelRatioPriority,
  getImageWithFallback,
  getSmartCropBackgroundImageLoader,
  getSmartCropImageLoaderByTokenName,
} from '@cms/utils/utils'
import type {
  StageBrandMediaReferences,
  StageMediaReference,
  StageProps,
} from '@components/Stage'
import { Stage } from '@components/Stage'
import type { TypeContentDamImageSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import { useTheme } from '@mui/material/styles'

import type { CMSStageProps } from './Stage.types'

const STAGE_TYPES = {
  FULL_STAGE: 'fullStage',
  BRAND_STAGE: 'brandStage',
  SPLIT_STAGE: 'splitStage',
}

const StageWrapper: React.FC<CMSStageProps> = ({ fields }) => {
  const theme = useTheme()
  const { devicePixelRatio } = useDevicePixelRatio()
  const { normalizeReference } = useNormalizeReference()
  const { primaryReference, secondaryReference, mediaReference, stageType } = fields || {}
  const isFullStage = stageType === STAGE_TYPES.FULL_STAGE
  const fallbackUrl = isFullStage
    ? FALLBACK_IMAGES.BACKGROUND
    : FALLBACK_IMAGES.DEFAULT_4_3_RATIO
  const mediaProps: StageMediaReference = {
    image: getImageWithFallback(
      (mediaReference as ContentEntry<TypeContentDamImageSkeleton>).fields?.image?.[0],
      fallbackUrl,
    ),
  }
  const analyticsProps: ComponentAnalyticsClickProps = {
    targetType: 'cta',
    cmsComponent: 'content_stage',
  }
  const primaryRef = normalizeReference({
    reference: primaryReference,
    analyticsProps,
  })
  const secondaryRef = normalizeReference({
    reference: secondaryReference,
    analyticsProps,
  })

  const getStageImageLoader = () => {
    const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

    if (stageType === 'fullStage') {
      return getSmartCropBackgroundImageLoader(
        CONTENTFUL_TYPES.CONTENT_STAGE,
        theme.breakpoints.values,
        devicePixelRatioPriority,
      )
    }
    return getSmartCropImageLoaderByTokenName(
      SMART_CROP_TOKENS.CF_4X3_L,
      devicePixelRatioPriority,
    )
  }

  const stageProps: StageProps & Partial<StageBrandMediaReferences> = {
    ...fields,
    ...mediaProps,
    primaryRef,
    secondaryRef,
    nextImageComponent: Image,
    // empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
    imageLoader: getStageImageLoader(),
    nextLinkComponent: Link,
  }

  if (stageType === STAGE_TYPES.BRAND_STAGE) {
    const backgroundImageLarge =
      theme.direction === 'rtl' ? STAGE_BRAND_IMAGES.RTL : STAGE_BRAND_IMAGES.LTR
    stageProps.bgImageSmall = {
      url: STAGE_BRAND_IMAGES.SMALL,
      title: 'Knauf - Background Image',
    }
    stageProps.bgImageLarge = {
      url: backgroundImageLarge,
      title: 'Knauf - Background Image',
    }
  }

  return <Stage {...stageProps} />
}

export default StageWrapper
