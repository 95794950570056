import type React from 'react'

import Square from '@mui/icons-material/Square'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { useStyles } from './BulletList.styles'
import type { BulletListProps } from './BulletList.types'
import BulletText from './BulletText'
import { isBulletItem } from './utils'

const BulletList: React.FC<BulletListProps> = ({
  bulletColorAndTextOverride,
  listItems,
  isTextBold = false,
  isNestedBullet,
  qaIdPrefix = 'list',
  sx,
}) => {
  const theme = useTheme()

  const styles = useStyles(theme, bulletColorAndTextOverride, isTextBold)

  return (
    <Box display="flex" flexDirection="column" sx={sx} data-cy={`${qaIdPrefix}-bullet`}>
      <List dense sx={styles.list}>
        {listItems?.map((listItem, index) => {
          return (
            <ListItem
              key={`${index}_${listItem}` as React.Key}
              sx={styles.listItem}
              alignItems="flex-start"
              data-cy={`${qaIdPrefix}-bullet-${index}`}
            >
              {isBulletItem(listItem, isNestedBullet) && (
                <ListItemIcon sx={styles.listIcon}>
                  {isNestedBullet ? (
                    <SquareOutlinedIcon sx={styles.icon} />
                  ) : (
                    <Square sx={styles.icon} />
                  )}
                </ListItemIcon>
              )}
              <Typography variant="body2dense" sx={styles.listText}>
                <BulletText textContent={listItem} bulletListComponent={BulletList} />
              </Typography>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default BulletList
