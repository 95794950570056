import type { ComponentAnalyticsClickProps, LabelContentUnion } from '@cms/analytics/types'
import { normalizeLabelFromReactNode } from '@cms/analytics/utils'
import type { ReferenceWeb } from '@knauf-group/ct-designs/utils/types'
import type { TypeExternalReferenceSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import type { TypeLinkSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types/TypeLink'
import { useReferenceWithOnClick } from '@knauf-group/ct-shared-nextjs/web/hooks'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import { getFormattedReference } from '@knauf-group/ct-shared-nextjs/web/utils/utils'

import { useGlobalContext } from './useGlobalContext'

export type NormalizeReferenceProps = {
  reference: ContentEntry<TypeLinkSkeleton | TypeExternalReferenceSkeleton>
  referenceLabelOverride?: LabelContentUnion
  analyticsProps?: ComponentAnalyticsClickProps
}

const useNormalizeReference = () => {
  const { siteStructure } = useGlobalContext()
  const { getReferenceWithOnClick } = useReferenceWithOnClick()

  // this needs to be exported as a function so it can be used within loops, conditions, or nested functions
  const normalizeReference = ({
    reference,
    referenceLabelOverride,
    analyticsProps,
  }: NormalizeReferenceProps) => {
    const { targetType: type, cmsComponent } = analyticsProps || {}
    const formattedReference = getFormattedReference(reference, siteStructure)
    // Label override is only needed for analytics action prop since richText inline label have priority over the content type's
    const referenceWithLabelOverride: ReferenceWeb = referenceLabelOverride
      ? {
          ...formattedReference,
          label: normalizeLabelFromReactNode(referenceLabelOverride),
        }
      : formattedReference

    return getReferenceWithOnClick({
      reference: referenceWithLabelOverride,
      type,
      eventPayload: {
        cms_component: cmsComponent,
      },
    })
  }

  return { normalizeReference }
}

export default useNormalizeReference
