import type React from 'react'

import BulletList from '@cms/components/BulletList/BulletList'
import { CTAComponent } from '@knauf-group/ct-designs/components/core/CTAComponent'
import { ImageWrapper } from '@knauf-group/ct-designs/components/core/ImageWrapper'
import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { StageProps } from './Stage.types'

const StageFull: React.FC<StageProps> = ({
  nextImageComponent,
  imageLoader,
  image,
  firstBulletItem,
  secondBulletItem,
  thirdBulletItem,
  headline,
  subHeadline,
  text,
  dataCy,
  primaryRef,
  secondaryRef,
  nextLinkComponent,
}) => {
  const theme = useTheme()
  const contrastColor = theme.palette.primary.contrastText
  const bulletItems = [firstBulletItem, secondBulletItem, thirdBulletItem].filter(
    (item) => item,
  )
  const hasBulletItems = bulletItems?.length > 0
  const alignment = hasBulletItems ? 'start' : 'center'

  const bgFullStageStyles: Styles = {
    backgroundGridContainer: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '364px',
      position: 'relative',
      justifyContent: 'center',
      color: theme.palette.white,
    },
    // TODO remove this and apply Image Background refactor when Next 13 Image is ready
    imageOverlay: {
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    image: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      inset: 0,
      objectFit: 'cover',
    },
    highlightText: {
      mb: '16px',
      p: '0',
    },
    textCenter: {
      textAlign: alignment,
    },
  }

  return (
    <Box position="relative" overflow="hidden" data-cy={dataCy}>
      {image?.url && (
        <ImageWrapper
          image={image}
          nextImageComponent={nextImageComponent}
          loader={imageLoader}
          priority
          sx={bgFullStageStyles.image}
        />
      )}
      {
        // TODO remove this and apply Image Background refactor when Next 13 Image is ready
        <Box sx={bgFullStageStyles.imageOverlay} />
      }
      <Box sx={bgFullStageStyles.backgroundGridContainer}>
        <Container sx={WEB_CONTAINER_VERTICAL_SPACE}>
          <Grid container spacing={{ xs: '0px', lg: 2 }} justifyContent={alignment}>
            <Grid item xs={12} lg={8}>
              <Typography
                variant="h1"
                color="white"
                sx={bgFullStageStyles.textCenter}
                data-cy={`${dataCy}-headline`}
              >
                {headline}
              </Typography>
              {subHeadline && (
                <Typography
                  variant="h3"
                  color={contrastColor}
                  sx={[bgFullStageStyles.textCenter, { mb: '24px' }]}
                  data-cy={`${dataCy}-sub-headline`}
                >
                  {subHeadline}
                </Typography>
              )}
              <Box display="flex" flexDirection="column" alignItems={alignment}>
                {text && (
                  <Typography
                    color={contrastColor}
                    variant="body2"
                    sx={[bgFullStageStyles.textCenter, bgFullStageStyles.highlightText]}
                    data-cy={`${dataCy}-text`}
                  >
                    {text}
                  </Typography>
                )}
                {hasBulletItems && (
                  <Box sx={bgFullStageStyles.highlightText}>
                    <BulletList
                      qaIdPrefix={dataCy}
                      listItems={bulletItems}
                      bulletColorAndTextOverride={contrastColor}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Stack alignItems={{ xs: 'stretch', sm: alignment }}>
            <CTAComponent
              primaryRef={primaryRef}
              secondaryRef={secondaryRef}
              dataCyPrefix={dataCy}
              nextLinkComponent={nextLinkComponent}
              buttonAlignment={alignment}
              prefetch={false}
            />
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

export default StageFull
