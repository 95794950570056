import { useEffect, useState } from 'react'

const useDevicePixelRatio = () => {
  const [currentDpr, setCurrentDpr] = useState(null)
  useEffect(() => {
    const { devicePixelRatio } = window
    setCurrentDpr(devicePixelRatio)
  }, [currentDpr])

  return { devicePixelRatio: currentDpr }
}

export default useDevicePixelRatio
