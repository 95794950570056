import type React from 'react'

import BulletList from '@cms/components/BulletList/BulletList'
import { CTAComponent } from '@knauf-group/ct-designs/components/core/CTAComponent'
import { ImageWrapper } from '@knauf-group/ct-designs/components/core/ImageWrapper'
import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import { ScreenMD, ScreenXS } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import type { QaHook, Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { StageProps } from './Stage.types'

const OverlineDetail = ({ text, dataCy, styles }) => (
  <Box sx={styles}>
    <Typography variant="overline" data-cy={dataCy}>
      {text}
    </Typography>
  </Box>
)

type OuterStageOverlineProps = {
  overlineTag: string
  overlineDate: string
  overlineAuthor: string
} & QaHook

const OuterStageOverline: React.FC<OuterStageOverlineProps> = ({
  overlineTag,
  overlineDate,
  overlineAuthor,
  dataCy,
}) => {
  const theme = useTheme()
  const styles: Styles = {
    outerStageOverlineBox: {
      display: 'flex',
      flexDirection: 'row',
      mb: overlineTag && overlineDate && overlineAuthor && '8px',
    },
    innerStageOverlineBox: {
      position: 'relative',
      '&:not(:first-of-type)': {
        paddingLeft: '8px',
        '&::before': {
          content: "''",
          display: 'block',
          height: '12px',
          width: '2px',
          position: 'absolute',
          left: '0',
          top: '40%',
          backgroundColor: theme.palette.text.secondary,
          color: theme.palette.text.secondary,
        },
      },
      '&:not(:last-of-type)': {
        paddingRight: '8px',
      },
    },
  }
  return (
    <Box sx={styles.outerStageOverlineBox}>
      {overlineTag && (
        <OverlineDetail
          text={overlineTag}
          dataCy={`${dataCy}-overline-tag`}
          styles={styles.innerStageOverlineBox}
        />
      )}
      {overlineDate && (
        <OverlineDetail
          text={overlineDate}
          dataCy={`${dataCy}-overline-date`}
          styles={styles.innerStageOverlineBox}
        />
      )}
      {overlineAuthor && (
        <OverlineDetail
          text={overlineAuthor}
          dataCy={`${dataCy}-overline-author`}
          styles={styles.innerStageOverlineBox}
        />
      )}
    </Box>
  )
}

const StageSplit: React.FC<StageProps> = ({
  overlineTag,
  overlineDate,
  overlineAuthor,
  image,
  dataCy,
  headline,
  subHeadline,
  text,
  firstBulletItem,
  secondBulletItem,
  thirdBulletItem,
  primaryRef,
  secondaryRef,
  nextImageComponent,
  imageLoader,
  nextLinkComponent,
}) => {
  const theme = useTheme()
  const isScreenMd = ScreenMD(theme)
  const isScreenXs = ScreenXS(theme)
  const spaceBetween = 'space-between'

  const styles: Styles = {
    foregroundGridContainer: {
      minHeight: { xs: 'calc(100vh - 64px)', sm: 'auto' },
      justifyContent: {
        xs: spaceBetween,
        lg: spaceBetween,
      },
      h1: {
        mb: theme.spacing(3),
      },
      color: theme.palette.text.primary,
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      width: '100%',
      height: '100%',
      aspectRatio: '4/3',
      objectFit: 'cover',
    },
    highlightText: {
      mb: { xs: '16px', lg: '24px' },
      p: '0',
    },
    textBody: {
      textAlign: 'start',
      mb: { xs: '16px', sm: '24px', lg: '24px' },
    },
    buttonContainer: {
      ...(isScreenMd && {
        mb: '40px',
      }),
    },
  }

  const bulletItems = [firstBulletItem, secondBulletItem, thirdBulletItem].filter(
    (item) => item,
  )

  return (
    <Box sx={[styles.foregroundGridContainer, WEB_CONTAINER_VERTICAL_SPACE]}>
      <Container
        maxWidth={isScreenXs ? false : 'xl'}
        disableGutters={isScreenXs}
        data-cy={dataCy}
      >
        <Container maxWidth={isScreenXs ? 'xl' : false} disableGutters={!isScreenXs}>
          <OuterStageOverline
            overlineTag={overlineTag}
            overlineDate={overlineDate}
            overlineAuthor={overlineAuthor}
            dataCy={dataCy}
          />

          <Typography
            variant="h1"
            color={theme.palette.secondary.main}
            sx={styles.h1}
            data-cy={`${dataCy}-headline`}
          >
            {headline}
          </Typography>
        </Container>
        <Grid container spacing={{ xs: theme.spacing(2), lg: theme.spacing(3) }}>
          <Grid item xs={12} sm={6}>
            <Container disableGutters sx={styles.flexContainer}>
              <ImageWrapper
                image={image}
                nextImageComponent={nextImageComponent}
                loader={imageLoader}
                sx={styles.image}
                dataCy={`${dataCy}-image-right`}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} xl={5} justifyContent="center" sx={styles.flexContainer}>
            <Container disableGutters={!isScreenXs}>
              {subHeadline && (
                <Typography
                  variant={isScreenMd ? 'h5' : 'h4'}
                  sx={styles.textBody}
                  data-cy={`${dataCy}-sub-headline`}
                >
                  {subHeadline}
                </Typography>
              )}
              <Box display="flex" flexDirection="column" alignItems="start">
                {text && (
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="body2"
                    sx={[styles.textBody, styles.highlightText]}
                    data-cy={`${dataCy}-text`}
                  >
                    {text}
                  </Typography>
                )}
                {bulletItems?.length > 0 && (
                  <Box sx={styles.highlightText}>
                    <BulletList qaIdPrefix={dataCy} listItems={bulletItems} />
                  </Box>
                )}
              </Box>
              <Stack sx={styles.buttonContainer} alignItems={{ xs: 'stretch', sm: 'start' }}>
                <CTAComponent
                  primaryRef={primaryRef}
                  secondaryRef={secondaryRef}
                  dataCyPrefix={dataCy}
                  nextLinkComponent={nextLinkComponent}
                  withMediaBackground={false}
                  buttonAlignment="start"
                  prefetch={false}
                />
              </Stack>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default StageSplit
