import { type FC, type JSX, type ReactNode, useId } from 'react'

import Box from '@mui/material/Box'

import { flexStyles } from './BulletList.styles'
import type { BulletListProps } from './BulletList.types'
import { getMappedBulletPointNodes } from './utils'

type TextContent = string | string[] | ReactNode | ReactNode[]

export const richTextNestedBulletListTestId = 'rich-text-nested-bullet-list'

export type BulletTextProps = {
  textContent: TextContent
  bulletListComponent: FC<BulletListProps>
}

/**
 * The Box component wrapping the textContent and its derived ones is required since
 * textContent can also be a ReactNode when it comes with bold html tags and since
 * we use flexbox with gap to handle spacing
 */
const BulletText: FC<BulletTextProps> = ({
  textContent,
  bulletListComponent: BulletList,
}): JSX.Element => {
  const textId = useId()
  if (!Array.isArray(textContent)) {
    return <Box>{textContent}</Box>
  }

  /*
    Handles edge case where a bullet item has a paragraph below, and they are not separated by new line character (\n).
    Visually, in contentful UI it will appear as though bullet item and paragraph are correctly separated by new line.
    But in the dataset there won't be any new line character between bullet item and paragraph.
    If the paragraph contains new line characters, it will be split into multiple `textContent` items, which causes this component to crash.
    In this case the data structure will look different from what we expect. We will receive a flat array without any nested arrays inside.
    This is why we are rendering the entire `textContent` as a simple paragraph instead of as part of bullet list.
    This most likely happens when users copy content from somewhere into the rich text field.
   */
  if (!textContent.some((item) => Array.isArray(item))) {
    return (
      <Box>
        {(textContent as ReactNode[]).map((item, index) => (
          <Box component="span" key={`${textId}-${index}` as React.Key}>
            {item}
          </Box>
        ))}
      </Box>
    )
  }

  const [bulletTextRootLevel, bulletTextNestedLevel] = textContent

  const nestedListItems = getMappedBulletPointNodes(bulletTextNestedLevel)

  return bulletTextNestedLevel && Array.isArray(nestedListItems) ? (
    <>
      <Box>{bulletTextRootLevel}</Box>
      <Box sx={flexStyles} data-cy={richTextNestedBulletListTestId}>
        <BulletList isNestedBullet listItems={nestedListItems} />
      </Box>
    </>
  ) : (
    <Box>{bulletTextRootLevel}</Box>
  )
}

export default BulletText
