import type { ReactNode } from 'react'

export const getMappedBulletPointNodes = (listItems: ReactNode): ReactNode => {
  if (!Array.isArray(listItems)) {
    return listItems
  }

  return listItems.map((parentNode) => {
    if (!parentNode?.props) {
      return parentNode
    }

    return parentNode.props?.children?.map((childNode) => childNode?.props?.children)
  })
}

/**
 * @param listItem could be a root bullet(string or array) or nested one(always array). Stage component
 * is an example in which listItem is just a string and listItem in components with RichText is still an array.
 * @param isNestedBullet is needed to identify if it should render a bullet icon if editors only add a breakline in nested bullet scenarios
 * @returns boolean
 */
export const isBulletItem = (listItem: string | string[], isNestedBullet: boolean) =>
  Boolean((!isNestedBullet && listItem) || Array.isArray(isNestedBullet && listItem?.[0]))
